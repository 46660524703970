import { PageBanner } from '@components/common';
import { Contact } from '@components/contact';
import { Banner, Result } from '@components/search';
import { Container } from '@components/ui';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// @ts-ignore
import f from '../data/faq.txt';

const FAQPage = () => {
  const [faq, setFaq] = useState(null);
  useEffect(() => {
    fetch(f)
      .then((r) => r.text())
      .then((s) => {
        console.log(s.split('\n\n'));
        setFaq(s.split('\n\n'));
      });
  }, []);

  return (
    <div className="bg-gray-100">
      <PageBanner title="FAQ" />
      <Container>
        <div className="py-8">
          {faq?.slice(0, faq.length / 2).map((_, i) => {
            return (
              <div className="mb-4">
                <h4 className="font-bold mb-2">{faq?.[2 * i]}</h4>
                <p className="text-secondary text-base">{faq?.[2 * i + 1]}</p>
              </div>
            );
          })}
        </div>
        <div className="text-center py-6 pb-12">
          <h4 className="font-bold text-gray-400">Your questions are not answered?</h4>
          <Link to="/contact" className="text-accent underline pb-1 leading-loose">Get in touch</Link>
        </div>
      </Container>
    </div>
  );
};

export default FAQPage;
