import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  createRoutesFromElements,
  Routes
} from 'react-router-dom';

import Layout from '../components/Layout';
import Landing from '../pages/Landing';
import Search from '@pages/Search';
import Service from '@pages/Service';
import ContactPage from '@pages/Contact';
import PortfolioPage from '@pages/Portfolio';
import FAQPage from '@pages/FAQ';
import About from '@pages/About';
import Privacy from '@pages/Privacy';
import Terms from '@pages/Terms';
import Services from '@pages/Services';
import CompanyPage from '@pages/companies/[companyName]';
import ModelPage from '@pages/models/modelCompany';

// const router = createRoutesFromElements(

// );

export default (
  <Routes>
    <Route path={`/`} element={<Layout />}>
      <Route index element={<Landing />} />
      <Route path={`/services`} element={<Services />} />
      <Route path={`/services/:id`} element={<Service />} />
      <Route
        path={`/contact`}
        element={<ContactPage />}
        // loader={({ request }) =>
        //   fetch('/api/dashboard.json', {
        //     signal: request.signal
        //   })
        // }
      />
      <Route path={`/portfolio`} element={<PortfolioPage />} />
      <Route path={`/portfolio/:companyName`} element={<CompanyPage />} />
      <Route path={`/model/:companyName`} element={<ModelPage />} />
      <Route path={`/faq`} element={<FAQPage />} />
      <Route path={`/about-us`} element={<About />} />
      <Route path={`/terms-and-service`} element={<Terms />} />
      <Route path={`/privacy-policy`} element={<Privacy />} />

      {/* <Route element={<AuthLayout />}>
    <Route path="login" element={<Login />} loader={redirectIfUser} />
    <Route path="logout" action={logoutUser} />
  </Route> */}
    </Route>
  </Routes>
);
