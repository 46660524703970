import { Container } from '@components/ui';
import {
  FaBuyNLarge,
  FaGripVertical,
  FaHandLizard,
  FaHandMiddleFinger,
  FaMoneyBill,
  FaMoneyCheck,
  FaRegFileArchive,
  FaRegGrinTears,
  FaRegHandshake,
  FaRegMoneyBillAlt,
  FaSellcast,
  FaWind
} from 'react-icons/fa';
import s from './Service.module.css';
import Img from '@assets/images/illustrations/loc.jpg';

const Service = () => {
  const list = [
    {
      title: 'DISCOVER',

      description:
        "We dive deep into our client's vision, goals, and requirements, gaining valuable insights to understand their dream project.",
      icon: <FaBuyNLarge />
    },

    {
      title: 'PLAN',

      description:
        'We create a comprehensive project plan, outlining the scope, timeline, and resources needed to bring the dream project to life.',
      icon: <FaBuyNLarge />
    },

    {
      title: 'DESIGN',

      description:
        "Our expert team designs captivating visuals and user-friendly interfaces that align with the client's brand and objectives.",
      icon: <FaBuyNLarge />
    },

    {
      title: 'DEVELOP',

      description:
        'Using cutting-edge technologies, we develop the dream project, focusing on performance, functionality, and security.',
      icon: <FaBuyNLarge />
    },

    {
      title: 'REFINE',

      description:
        'We collaborate closely with the client, gathering feedback and making iterative adjustments to ensure a perfect fit.',
      icon: <FaBuyNLarge />
    },

    {
      title: 'DELIVER',

      description:
        'With rigorous quality assurance, we deliver the final product, ready for deployment or launch.',
      icon: <FaBuyNLarge />
    },

    {
      title: 'SUPPORT',

      description:
        'Even after the dream project is live, our dedicated team provides ongoing support and maintenance to ensure its success.',
      icon: <FaBuyNLarge />
    }
  ];
  return (
    <div id="feature" className="bg-white">
      <Container>
        <div className="grid gap-3 grid-cols-11">
          <div className="col-span-12 lg:col-span-5 self-base mb-6">
            <div className="relative flex items-end pt-12 h-full">
              <span className={s.buyCircle}>
                Accuracy <div className="text-black ml-1">100%</div>
              </span>
              <span className={s.sellCircle}>
                Ontime <span className="text-black ml-1">99%</span>{' '}
              </span>
              <span className={s.rentCircle}>
                Secured <div className="text-black ml-1">100%</div>{' '}
              </span>
              <img
                src={Img}
                // width={850}
                // height={678}
                alt="Service img"
                className={s.serviceImg}
              />
            </div>
          </div>
          <div className="col-span-12 lg:col-span-6 flex flex-col justify-center mb-8 lg:py-8 lg:py-20">
            <h1 className="font-bold text-2xl lg:text-5xl mb-4 lg:mb-6 pt-8">
              Your Dream Project
            </h1>

            <h4 className="text-secondary lg:mb-6 lg:ml-4">
              Transforming a vision into reality{' '}
            </h4>

            <div className="grid lg:grid-cols-2 gap-4 container mt-4">
              {list.map(({ title, description, icon }, index) => (
                <div
                  className={
                    'col-span-1 border-none lg:col-span-1 ' +
                    (index % 2 == 0 ? 'border-r ' : 'border-l ') +
                    (index < 2 ? 'border-b ' : 'border-t ') +
                    (index == list.length - 1 ? ' lg:col-span-2 ' : '')
                  }
                  key={String(index)}>
                  <div
                    className={
                      'px-3 pr-2 py-3 h-30 h-full shadow-gray-200 shadow-lg rounded-xl flex items-center ' +
                      (index == list.length - 1 ? ' px-4 py-4 ' : '')
                    }>
                    <div className="bg-accent rounded-full h-10 w-10 text-white flex items-center justify-center">
                      {icon}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column'
                      }}>
                      <h6
                        className="mb-1 pl-2 uppercase"
                        style={{
                          fontSize: '1rem'
                        }}>
                        {title}
                      </h6>
                      <h4 className="text-gray-400 pl-2 text-xs">
                        {' '}
                        {description}
                      </h4>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Service;
