import { Button, Rating } from '@components/common';
import React from 'react';
import { FaArrowRight, FaStar } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import s from './Homes.module.css';

const HomeCardItem = ({ item, isSearchResult = false }) => {
  const navigate = useNavigate();
  return (
    <div className="px-1 lg:px-2 py-1 lg:py-2 h-full">
      <div
        className={
          s.homeCard +
          ' p-4 lg:p-6 lg:py-6 rounded-md bg-white transition-all h-full shadow-md shadow-gray-200 hover:shadow-lg' +
          (isSearchResult ? ' shadow-lg' : '')
        }>
        <div
          className="mb-4 text-2xl border text-accent rounded-full p-4 h-16 w-16 flex items-center justify-center text-gray-400"
          style={{
            lineHeight: 0,
            overflow: 'hidden'
          }}>
          {/* <img
            src={item.cover}
            width={760}
            height={760}
            alt={'Course ' + item.id}
          /> */}
          {item.icon}
        </div>
        <div className="mb-2">
          <h4 className="mb-2 lg:h-12 text-xl font-bold mb-4">{item.title}</h4>
          <p className="text-sm text-gray-400">{item.description}</p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* <Rating value={item.rating} /> */}
            {/* <span>({item.ratingCount})</span> */}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <div className="flex flex-wrap lg:flex-nowrap items-center">
            <Link to={`/services/${item.id}`} className="text-accent">
              Details
            </Link>
          </div>
          <button
            onClick={() => navigate(`/services/${item.id}`)}
            className="p-4 detail-icon rounded-full hover:!bg-accent hover:!text-white hover:!shadow-lg transition-all duration-300">
            <FaArrowRight className="text-sm lg:text-lg" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeCardItem;
