// import type { Testimonial } from "@/interfaces/testimonial";

export const data = [
  {
    id: 1,
    title: 'Exceeding Expectations!',
    content:
      '“OZKode exceeded our expectations with their exceptional software development skills. They delivered our customized website that perfectly met our needs, helping us streamline operations and achieve remarkable results.“',
    user: {
      id: 1,
      name: 'Owner',
      professional: 'Rowles and James Cleaning Services',
      photo:
        'https://i.ibb.co/dtq8H6T/cleaning.png'
    }
  },
  {
    id: 2,
    title: 'Game-Changing Property Tech!',
    content:
      '"Working with OZKode has been a game-changer for our business. Their expertise in Property Tech software development is unmatched, and their dedication to our success is truly commendable.“',
    user: {
      id: 1,
      name: 'Dr Jia,',
      professional: 'CEO, Wurley Property Technology',
      photo:
        'https://i.ibb.co/NjM4ccm/jia.jpg'
    }
  },
  {
    id: 3,
    title: 'Outstanding Content Creators!',
    content:
      '"OZKode\'s digital marketing solutions have taken our online presence to new heights. Their strategies and expertise have helped us achieve higher visibility, increased traffic, and improved customer engagement. We highly recommend OZKode for all your software and digital marketing needs."',
    user: {
      id: 1,
      name: 'Harsha',
      professional: 'Director, HK Home Loans',
      photo:
        'https://i.ibb.co/jrCYXVn/harsha.jpg'
    }
  },

  {
    id: 4,
    title: 'Timely and Reliable Services!',
    content:
      '"OZKode created an outstanding school website for us. It beautifully showcases our school and has improved communication with students, parents, and staff. We\'re thrilled with the results!"',
    user: {
      id: 1,
      name: 'South East Bangla School (SEBS)',
      professional: 'Melbourne, Australia',
      photo:
        'https://i.ibb.co/1mVDp80/sebangla.jpg'
    }
  }
];
