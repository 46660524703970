import { Contact } from '@components/contact';
import { Banner, Result } from '@components/search';

const ContactPage = () => {
  console.log("url: ", process.env);

  return (
    <div>
      <Contact />
    </div>
  );
};

export default ContactPage;
