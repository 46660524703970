import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router';
import Footer from './Footer';
import Header from './Header';
import s from './Layout.module.css';

const Layout = ({ children = null }) => {
  const [isOther, setIsOther] = useState(false);
  const loc = useLocation();

  useEffect(() => {
    setIsOther(loc.pathname != '/');
  }, [loc.pathname]);

  return (
    <div className={isOther ? 'layout' : 'layout-in-home'}>
      <Header />
      <div className={s.content}>{children ? children : <Outlet />}</div>
      <Footer />
    </div>
  );
};

export default Layout;
