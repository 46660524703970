import React, { FC, useEffect, useRef } from 'react';
import Slider, { Settings } from 'react-slick';
import { HomeCardItem } from '@components/homes';
import {
  FaAngleRight,
  FaAngleLeft,
  FaArrowRight,
  FaFacebook,
  FaGit,
  FaGithub,
  FaVoicemail,
  FaEnvelope,
  FaEnvelopeSquare,
  FaEnvelopeOpenText,
  FaRegEnvelope
} from 'react-icons/fa';
import Container from '@components/ui/Container';
import ImgHome1 from '../../../assets/images/home-6.png';
import ImgHome2 from '../../../assets/images/home-7.png';
import { Link } from 'react-router-dom';
import mohsin from '@assets/images/team/mohsin-khan.png';
import monisha from '@assets/images/team/monisha.png';

const Result = () => {
  const mqlRef = useRef(null);

  useEffect(() => {
    mqlRef.current = window.matchMedia('(max-width: 600px)');
  }, []);

  const cto = {
    name: 'Mohsin Khan',
    designation: 'CTO',
    img: mohsin
  };

  const members = [
    {
      name: 'Yadab Sutradhar',
      designation: 'Software Engineer',
      img: ''
    },
    {
      name: 'Monisha Dev',
      designation: 'Software Engineer',
      img: monisha
    }
  ];

  return (
    <div className="bg-gray-100/50 py-20">
      <Container>
        <h2 className="font-bold text-4xl text-center mb-6">Team</h2>
        <div className="pb-6 flex flex-col items-center justify-center">
          <div>
            <Card data={cto} />
          </div>
          <div className="flex lg:grid-cols-3">
            {members.map((m) => (
              <Card data={m} />
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

const Card = ({ data }) => {
  return (
    <div className="p-4 shadow m-4 bg-white rounded-md">
      <div className="">
        <img
          src={
            data?.img ||
            'https://t4.ftcdn.net/jpg/05/42/36/11/360_F_542361185_VFRJWpR2FH5OiAEVveWO7oZnfSccZfD3.jpg'
          }
          alt=""
          className="h-60 w-60 object-cover object-top"
          style={{filter: "brightness(1.05) contrast(1.05) grayscale(.1)"}}
        />
      </div>
      <div className="p-4 pb-0 text-center">
        <h4 className="text-xl font-bold">{data?.name}</h4>
        <p className=" text-secondary">{data?.designation}</p>
      </div>
      <div className="p-4 pb-0 flex justify-center text-center">
        <FaFacebook className="text-xl text-blue-600 p-1 h-7 w-8" />
        <FaGithub className="text-xl text-gray-600 p-1 h-7 w-8" />
        <FaRegEnvelope className="text-xl text-accent p-1 h-7 w-8" />
      </div>
    </div>
  );
};

export default Result;
