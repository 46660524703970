import { Container } from '@components/ui';
import { Button, ScrollAnimationWrapper } from '@components/common';
import { portfolios } from '@data/dynamic';
import Banner from '@components/common/PageBanner';
import React, { useEffect } from 'react';
import useDocumentTitle from '@utils/useTitle';
import { Link } from 'react-router-dom';

const Company = ({ name }) => {
  const item = portfolios?.find(
    (p) => p.name?.toLowerCase() == name?.toLowerCase()
  );
  useDocumentTitle(`${item?.name || name} | ${item?.domain || 'Details'}`);

  return (
    <div id="testimonial" className="testimonial-page bg-light lg:pt-0 pb-20">
      <Banner title={item?.name || name} />
      <Container>
        <ScrollAnimationWrapper className="rounded-lg w-full pt-20">
          <div className="section-wrap lg:!mb-12">
            <div className="grid gap-6 lg:grid-cols-5">
              <div
                className={'lg:mb-6 lg:order-0 lg:col-start-0 lg:col-span-2'}>
                <img
                  className=" object-cover bg-white w-full lg:h-80 !rounded-none border-4 outline-4 !p-6"
                  src={item.banner}
                  alt="logo"
                />
              </div>

              <div
                className={
                  'testimonial-boxx py-6 mb-6 !shadow-none !p-4 !items-start !bg-transparent !text-left ' +
                  (true
                    ? '  order-1 lg:col-span-3 text-right'
                    : ' !items-end order-0 lg:col-span-3')
                }>
                <h2
                  className={
                    'text-xl mb-4 font-bold text-gray-600 ' +
                    (true ? '' : ' !text-right')
                  }>
                  {item.name}
                </h2>
                <p
                  className={
                    'text-base text-gray-400 mb-4 ' +
                    (true ? '' : ' !text-right')
                  }
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
                <div className={' w-full mb-4 ' + (true ? '' : ' !text-right')}>
                  <div className="text-sm text-gray-400 font-bold mr-2 inline-block">
                    Website
                  </div>
                  <div className="text-sm text-accent inline-block">
                    <a href={item?.link}>@{item.name}</a>
                  </div>
                </div>
                <div className="mb-2">
                  <Button size="sm">
                    <Link to={`/portfolio`}>Back</Link>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </ScrollAnimationWrapper>
      </Container>
    </div>
  );
};

export default Company;
