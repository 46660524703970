//
export const data = [
  {
    title: 'Privacy policy',
    para: [
      'This privacy policy ("Policy") describes how the personally identifiable information ("Personal Information") you may provide in the "OZKode" mobile application and website ("Mobile Application" or "Service" or “Website”) and any of its related products and services (collectively, "Services") is collected, protected and used. OZKode and Wurley may be used interchangeably throughout the privacy policy. It also describes the choices available to you regarding our use of your Personal Information; and how you can access and update this information. This Policy is a legally binding agreement between you ("User", "you" or "your") and this Mobile Application or website developer ("Operator", "we", "us" or "our"). By accessing and using the Mobile Application, OZKode Website, and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. This Policy does not apply to the practices of companies that we do not own or control, or to individuals that we do not employ or manage.'
    ],
    subtitle: '',
    list: [],
    para2: [],
    subtitle2: '',
    list2: []
  },
  {
    title: 'Automatic collection of information',
    para: [
      'When you use the Mobile Application and Website, our servers automatically record information that your device sends. This data may include information such as your device&#39;s IP address and location, device name and version, operating system type and version, language preferences, information you search for in the Mobile Application or Website, access times and dates, and other statistics.',
      'Information collected automatically is used only to identify potential cases of abuse and establish statistical information regarding the usage of the Mobile Application or Website and Services. This statistical information is not otherwise aggregated in such a way that would identify any particular user of the system.'
    ],
    subtitle: '',
    list: [],
    para2: [],
    subtitle2: '',
    list2: []
  },
  {
    title: 'Collection of personal information',
    para: [
      'For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information. The information that we request will be retained by us and used as described in this privacy policy. The app does use third party services that may collect information used to identify you. Link to privacy policy of third-party service providers used by the Mobile Application and Website.'
    ],
    subtitle: '',
    list: [
      'Google Play Services',
      'AdMob',
      'Google Analytics for Firebase',
      'Firebase Crashlytics',
      'Facebook',
      'Fabric',
      'Matomo',
      'Clicky',
      'Flurry Analytics',
      'Appodeal',
      'Fathom Analytics',
      'Unity',
      'SDKBOX',
      'GameAnalytics',
      'One Signal',
      'Expo',
      'Sentry',
      'AppLovin',
      'Vungle',
      'StartApp',
      'AdColony',
      'Amplitude',
      'Adjust'
    ],
    para2: [],
    subtitle2:
      'We receive and store any information you knowingly provide to us when you create an account or fill any online forms in the Mobile Application or Website. When required, this information may include the following:',
    list2: [
      'Personal details such as name, country of residence, date of birth, passport number, other identity documents, bank details, etc.',
      'Contact information such as email address, address, etc.',
      'Account details such as username, unique user ID, password, etc.',
      'Geolocation data such as latitude and longitude.',
      'Certain features on the mobile device such as contacts, calendar, gallery, etc.',
      'Information about other individuals such as your family members, friends, etc.',
      'Any other materials you willingly submit to us such as articles, images, feedback, etc.'
    ]
  },
  {
    title: 'Use and processing of collected information',
    para: [],
    subtitle:
      'In order to make the Mobile Application or Website and Services available to you, or to meet a legal obligation, we need to collect and use certain Personal Information. If you do not provide the information that we request, we may not be able to provide you with the requested products or services. Some of the information we collect is directly from you via the Mobile Application and Services. However, we may also collect Personal Information about you from other sources. Any of the information we collect from you may be used for the following purposes:',
    list: [
      'Create and manage user accounts',
      'Send administrative information',
      'Respond to inquiries and offer support',
      'Request user feedback',
      'Improve user experience',
      'Administer prize draws and competitions',
      'Enforce terms and conditions and policies',
      'Protect from abuse and malicious users',
      'Respond to legal requests and prevent harm',
      'Run and operate the Mobile Application or Website and Services,'
    ],
    para2: [
      'Processing your Personal Information depends on how you interact with the Mobile Application or Website and Services, where you are located in the world and if one of the following applies: (i) you have given your consent for one or more specific purposes; this, however, does not apply, whenever the processing of Personal Information is subject to European data protection law; (ii) provision of information is necessary for the performance of an agreement with you and/or for any pre-contractual obligations thereof; (iii) processing is necessary for compliance with a legal obligation to which you are subject; (iv) processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in us; (v) processing is necessary for the purposes of the legitimate interests pursued by us or by a third party.',
      'Note that under some legislations we may be allowed to process information until you object to such processing (by opting out), without having to rely on consent or any other of the following legal bases below. In any case, we will be happy to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Information is a statutory or contractual requirement, or a requirement necessary to enter into a contract.'
    ],
    subtitle2: '',
    list2: []
  },
  {
    title: 'Managing information',
    para: [
      'You are able to delete certain Personal Information we have about you. The Personal Information you can delete may change as the Mobile Application or Website and Services change. When you delete Personal Information, however, we may maintain a copy of the unrevised Personal Information in our records for the duration necessary to comply with our obligations to our affiliates and partners, and for the purposes described below. If you would like to delete your Personal Information or permanently delete your account, you can do so on the settings page of your account in the Mobile Application or Website.'
    ],
    subtitle: '',
    list: [],
    para2: [],
    subtitle2: '',
    list2: []
  },
  {
    title: 'Disclosure of information',
    para: [
      'Depending on the requested Services or as necessary to complete any transaction or provide any service you have requested, we may share your information with your consent with our trusted third parties that work with us, any other affiliates and subsidiaries we rely upon to assist in the operation of the Mobile Application or Website and Services available to you. We do not share Personal Information with unaffiliated third parties. These service providers are not authorized to use or disclose your information except as necessary to perform services on our behalf or comply with legal requirements. We may share your Personal Information for these purposes only with third parties whose privacy policies are consistent with ours or who agree to abide by our policies with respect to Personal Information. These third parties are given Personal Information they need only in order to perform their designated functions, and we do not authorize them to use or disclose Personal Information for their own marketing or other purposes.',
      'We will disclose any Personal Information we collect, use or receive if required or permitted by law, such as to comply with a subpoena, or similar legal process, and when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.'
    ],
    subtitle: '',
    list: [],
    para2: [],
    subtitle2: '',
    list2: []
  },
  {
    title: 'Retention of information',
    para: [
      'We will retain and use your Personal Information for the period necessary to comply with our legal obligations, resolve disputes, and enforce our agreements unless a longer retention period is required or permitted by law. We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally. Once the retention period expires, Personal Information shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after the expiration of the retention period.'
    ],
    subtitle: '',
    list: [],
    para2: [],
    subtitle2: '',
    list2: []
  },
  {
    title: 'The rights of users',
    para: [
      'You may exercise certain rights regarding your information processed by us. In particular, you have the right to do the following: (i) you have the right to withdraw consent where you have previously given your consent to the processing of your information; (ii) you have the right to object to the processing of your information if the processing is carried out on a legal basis other than consent; (iii) you have the right to learn if information is being processed by us, obtain disclosure regarding certain aspects of the processing and obtain a copy of the information undergoing processing; (iv) you have the right to verify the accuracy of your information and ask for it to be updated or corrected; (v) you have the right, under certain circumstances, to restrict the processing of your information, in which case, we will not process your information for any purpose other than storing it; (vi) you have the right, under certain circumstances, to obtain the erasure of your Personal Information from us; (vii) you have the right to receive your information in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that your information is processed by automated means and that the processing is based on your consent, on a contract which you are part of or on pre-contractual obligations thereof.'
    ],
    subtitle: '',
    list: [],
    para2: [],
    subtitle2: '',
    list2: []
  },
  {
    title: 'Privacy of children',
    para: [
      'We do not knowingly collect any Personal Information from children under the age of 18 years. If you are under the age of 18, please do not submit any Personal Information through the Mobile Application and Services. We encourage parents and legal guardians to monitor their children&#39;s Internet usage and to help enforce this Policy by instructing their children never to provide Personal Information through the Mobile Application or Website and Services without their permission. If you have reason to believe that a child under the age of 18 has provided Personal Information to us through the Mobile Application or Website and Services, please contact us. You must also be old enough to consent to the processing of your Personal Information in your country (in some countries we may allow your parent or guardian to do so on your behalf).'
    ],
    subtitle: '',
    list: [],
    para2: [],
    subtitle2: '',
    list2: []
  },
  {
    title: 'Email marketing',
    para: [
      'We offer electronic newsletters to which you may voluntarily subscribe at any time. We are committed to keeping your e-mail address confidential and will not disclose your email address to any third parties except as allowed in the information use and processing section. We will maintain the information sent via e-mail in accordance with applicable laws and regulations.',
      'In compliance with the CAN-SPAM Act, all e-mails sent from us will clearly state who the e-mail is from and provide clear information on how to contact the sender. You may choose to stop receiving our newsletter or marketing emails by following the unsubscribe instructions included in these emails or by contacting us. However, you will continue to receive essential transactional emails.'
    ],
    subtitle: '',
    list: [],
    para2: [],
    subtitle2: '',
    list2: []
  },
  {
    title: 'Links to other resources',
    para: [
      'The Mobile Application or Website and Services contain links to other resources that are not owned or controlled by us. Please be aware that we are not responsible for the privacy practices of such other resources or third parties. We encourage you to be aware when you leave the Mobile Application or Website and Services and to read the privacy statements of each and every resource that may collect Personal Information.'
    ],
    subtitle: '',
    list: [],
    para2: [],
    subtitle2: '',
    list2: []
  },
  {
    title: '',
    para: [],
    subtitle: '',
    list: [],
    para2: [],
    subtitle2: '',
    list2: []
  },
  {
    title: '',
    para: [],
    subtitle: '',
    list: [],
    para2: [],
    subtitle2: '',
    list2: []
  },
  {
    title: '',
    para: [],
    subtitle: '',
    list: [],
    para2: [],
    subtitle2: '',
    list2: []
  },
  {
    title: '',
    para: [],
    subtitle: '',
    list: [],
    para2: [],
    subtitle2: '',
    list2: []
  },
  {
    title: '',
    para: [],
    subtitle: '',
    list: [],
    para2: [],
    subtitle2: '',
    list2: []
  },
  {
    title: '',
    para: [],
    subtitle: '',
    list: [],
    para2: [],
    subtitle2: '',
    list2: []
  },
  {
    title: '',
    para: [],
    subtitle: '',
    list: [],
    para2: [],
    subtitle2: '',
    list2: []
  },
  {
    title: '',
    para: [],
    subtitle: '',
    list: [],
    para2: [],
    subtitle2: '',
    list2: []
  },
  {
    title: '',
    para: [],
    subtitle: '',
    list: [],
    para2: [],
    subtitle2: '',
    list2: []
  },
  {
    title: '',
    para: [],
    subtitle: '',
    list: [],
    para2: [],
    subtitle2: '',
    list2: []
  }
];
