import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Link,
  useLocation,
  useNavigate,
  useNavigation
} from 'react-router-dom';
import Logo from '@components/ui/Logo';
import Button from '@components/common/Button';
import s from './Layout.module.css';
import data from '@data/static.json';
import {
  FaEnvelope,
  FaMobile,
  FaMobileAlt,
  FaRegEnvelope,
  FaSearch
} from 'react-icons/fa';

const Header = () => {
  const [activeLink, setActiveLink] = useState(null);
  const [scrollActive, setScrollActive] = useState(false);
  const [showSearch, seShowSearch] = useState(false);
  const nav = data.nav;
  const navDown = data.navDown;

  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef(null);

  const addScrollEvent = useCallback((e) => {
    setScrollActive(window.scrollY > 20);
  }, []);

  useEffect(() => {
    window.removeEventListener('scroll', addScrollEvent);
    if (location.pathname == '/') {
      ref.current = window.addEventListener('scroll', addScrollEvent);
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname !== '/') {
      window.removeEventListener('scroll', addScrollEvent);
      setScrollActive(true);
    } else {
      setScrollActive(false);
    }
    setActiveLink(location.pathname);
  }, [location]);

  return (
    <>
      <header
        className={
          s.headerWrapper +
          (scrollActive ? ' shadow-sm pt-0 bg-white' : ' pt-0')
        }>
        <nav
          className={
            'max-w-screen-xl px-4 sm:px-8 grid-cols-11 lg:px-16 mx-auto grid grid-flow-col py-3 sm:py-6' +
            (scrollActive ? ' sm:!py-4' : '')
          }>
          <div className="col-start-1 col-end-3 flex items-center">
            <div
              className={
                'transition-all duration-500 ' +
                (scrollActive
                  ? ' bg-transparent rounded-full p-2 flex items-center lg:p-6 lg:relative'
                  : ' rounded-full p-2 lg:p-6 flex items-center ')
              }
              style={
                scrollActive
                  ? {
                      marginBottom: -50,
                      marginTop: -50
                      // boxShadow: '0 20px 20px -20px rgba(0, 0, 0, .3)'
                    }
                  : { marginBottom: -50, marginTop: -50 }
              }>
              <Logo
                orientation="v"
                className={'transition-all duration-500 '}
              />
            </div>
          </div>
          <ul className="hidden ml-auto lg:flex col-start-3 col-end-10 text-black-500  items-center">
            {nav.map((item, i) => (
              <li
                key={i}
                className={
                  'px-4 py-2 mx-2 font-bold cursor-pointer animation-hover inline-block relative' +
                  (activeLink === item.link
                    ? ' !text-accent animation-active '
                    : ' hover:text-accent a') +
                  (scrollActive ? ' text-white' : ' text-white')
                }>
                <Link to={item.link} className="hover:!text-accent">
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
          <div
            className={
              'col-start-3 lg:col-start-10 col-end-12 font-medium flex justify-end items-center relative ' +
              (scrollActive ? 'mt-0' : 'my-4 lg:my-0')
            }>
            {/* <input
              type="text"
              placeholder="search by state, zip"
              className={
                'border rounded-full p-2 px-4 absolute w-60 lg:w-52 transition-all duration-500 top-16 lg:top-auto right-0 outline-accent ' +
                (showSearch ? 'block' : 'hidden')
              }
            />
            <button
              className="p-2 py-4 mr-0 text-accent"
              onClick={() => seShowSearch(!showSearch)}>
              <FaSearch />
            </button> */}
            <Button outlined>
              <Link to="/contact">Contact</Link>
            </Button>
          </div>
        </nav>

        <nav
          style={activeLink !== '/' ? { display: 'none' } : {}}
          className="bg-white shadow">
          <ul className="max-w-screen-xl mx-auto hidden lg:flex items-center  px-4 sm:px-8 grid-cols-11 lg:px-16 grid grid-flow-col py-2 sm:py-2">
            {navDown.map((item, i) => (
              <li
                key={i}
                className={
                  'px-4 py-1 mx-2 font-bold text-xs cursor-pointer animation-hover inline-block relative' +
                  (activeLink === item.link
                    ? ' !text-accent animation-active '
                    : ' hover:text-accent a') +
                  (scrollActive ? ' text-gray-500' : ' text-gray-500')
                }>
                <Link to={item.link} className="flex items-center">
                  {i == 0 ? <FaRegEnvelope /> : <FaMobileAlt />}
                  <span className="px-2"> {item.name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </header>

      <nav className="bg-white fixed lg:hidden bottom-0 left-0 right-0 z-20 px-4 sm:px-8 shadow-t ">
        <div className="bg-white-500 sm:px-3">
          <ul className="flex w-full justify-between items-center text-black-500">
            <li
              className={
                'mx-1 sm:mx-2 px-3 sm:px-4 py-2 flex flex-col items-center text-xs border-t-2 transition-all ' +
                (activeLink === 'about'
                  ? '  border-accent text-accent'
                  : ' border-transparent')
              }
              onClick={() => navigate('/portfolio')}>
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              Portfolio
            </li>
            <li
              className={
                'mx-1 sm:mx-2 px-3 sm:px-4 py-2 flex flex-col items-center text-xs border-t-2 transition-all ' +
                (activeLink === 'feature'
                  ? '  border-accent text-accent'
                  : ' border-transparent ')
              }
              onClick={() => navigate('/contact')}>
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                />
              </svg>
              Contact
            </li>
            <li
              className={
                'mx-1 sm:mx-2 px-3 sm:px-4 py-2 flex flex-col items-center text-xs border-t-2 transition-all ' +
                (activeLink === 'testimoni'
                  ? '  border-accent text-accent'
                  : ' border-transparent ')
              }
              onClick={() => navigate('/services')}>
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                />
              </svg>
              Services
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Header;
