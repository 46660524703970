import { Container } from '@components/ui';
import React, { FC } from 'react';
import img from '@assets/images/girl-book.webp';

const Features = ({ list }) => {
  return (
    <div id="feature">
      <img
        src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' width='100%25' height='100' viewBox='0 0 1600 130'%3E%3Cpath d='M1600 130H0V20c400 0 400 100 800 100s400-100 800-100v110z' fill='%23FFF'/%3E%3ClinearGradient id='bg' gradientUnits='userSpaceOnUse' x1='0' y1='60' x2='1600' y2='60'%3E%3Cstop offset='0' stop-color='%23808'/%3E%3Cstop offset='.5' stop-color='%23CC3F47'/%3E%3Cstop offset='1' stop-color='%23F09F33'/%3E%3C/linearGradient%3E%3Cpath d='M0 10c400 0 400 100 800 100s400-100 800-100' fill='none' stroke='url(%23bg)' stroke-width='5'/%3E%3C/svg%3E"
        alt=""
        className="w-full opacity-70"
        style={{ transform: 'rotateZ(180deg)', minWidth: '180vw' }}
      />
      <div className="lg:pt-12 mb-20">
        <Container>
          <div className="grid grid-cols-12 gap-3">
            <div className="gridx col-span-12 grid-cols-12 gap-7">
              <div className="mb-8">
                <h1 className="font-bold text-2xl lg:text-5xl mb-6">
                  Service We Offer
                </h1>

                <p className="text-gray-500 m-4">
                  Submit your request and Get Quotations from Experts
                </p>
              </div>

              <div className="grid gap-4 grid-cols-12">
                {list.map(({ title, description, icon }, index) => (
                  <div
                    key={String(index)}
                    className="col-span-12 lg:col-span-6 bg-white">
                    <div className="px-4 py-5 flex items-center rounded-lg shadow-xl h-full shadow-gray-200/80">
                      <div className="flex p-4 bg-accent text-lg mr-4 text-white items-center justify-center rounded-full">
                        {icon}
                      </div>
                      <div className="flex flex-col flex-grow">
                        <h4 className="text-base font-semibold mb-2">
                          {title}
                        </h4>
                        <h6 className="text-gray-400 text-sm">{description}</h6>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="pt-6 lg:pt-12 bg-white mb-12">
        <Container>
          <div className="grid grid-cols-3 gap-6">
            <div className="col-span-3 lg:col-span-1 grid grid-cols-5">
              <h4 className="text-lg font-semibold mr-6 self-center col-span-2 text-center">Backend</h4>
              <ul className="flexx items-center mx-4">
                <li className="px-4 py-1 text-gray-400 border-l-4">Node</li>
                <li className="px-4 py-1 text-gray-400 border-l-4">Java</li>
                <li className="px-4 py-1 text-gray-400 border-l-4">PHP</li>
              </ul>
            </div>
            <div className="col-span-3 lg:col-span-1 grid grid-cols-5">
              <h4 className="text-lg font-semibold mr-6 self-center col-span-2 text-center">Frontend</h4>
              <ul className="flexx items-center mx-4">
                <li className="px-4 py-1 text-gray-400 border-l-4">React</li>
                <li className="px-4 py-1 text-gray-400 border-l-4">Angular</li>
                <li className="px-4 py-1 text-gray-400 border-l-4">Vue</li>
              </ul>
            </div>
            <div className="col-span-3 lg:col-span-1 grid grid-cols-5">
              <h4 className="text-lg font-semibold mr-6 self-center col-span-2 text-center">Database</h4>
              <ul className="flexx items-center mx-4">
                <li className="px-4 py-1 text-gray-400 border-l-4">MySQL</li>
                <li className="px-4 py-1 text-gray-400 border-l-4">
                  PostgreSQL
                </li>
                <li className="px-4 py-1 text-gray-400 border-l-4">MongoDB</li>
              </ul>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Features;
