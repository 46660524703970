import { Container } from '@components/ui';
import React, { FC } from 'react';
import img from '@assets/images/girl-book.webp';

const Features = ({ list }) => {
  return (
    <div id="">
      <div className="lg:pt-12 mb-12">
        <Container>
          <div className="grid grid-cols-12 gap-3">
            <div className="gridx col-span-12 grid-cols-12 gap-7">
              <div className="mb-8">
                <h1 className="font-bold text-2xl lg:text-4xl mb-6">
                  Our story
                </h1>
                <p className="font-bold text-gray-400">We are driven by values</p>
              </div>

              <div className="text-secondary">
                <p className="mb-4">
                  At OZKode, we are not just a software company; we are your
                  strategic partner in the digital realm. With years of
                  experience and a deep understanding of the industry, we have
                  honed our expertise in fintech, property tech, e-commerce
                  software, website development, and digital marketing
                  solutions. Our team of highly skilled software engineers
                  possesses a relentless drive for innovation and a keen eye for
                  detail.
                </p>

                <p className="mb-4">
                  We go beyond simply developing software; we craft
                  transformative solutions that propel businesses towards
                  success. By leveraging cutting-edge technologies and industry
                  best practices, we create custom software solutions that align
                  perfectly with our clients' goals and aspirations. Whether
                  it's streamlining financial processes, revolutionizing
                  property management, enhancing e-commerce experiences, or
                  boosting online visibility, we have the knowledge and skills
                  to deliver exceptional results.
                </p>

                <p className="mb-4">
                  What sets us apart is our unwavering commitment to our
                  clients' success. We believe that collaboration and open
                  communication are the cornerstones of a successful
                  partnership. We work closely with our clients, taking the time
                  to understand their unique needs, challenges, and aspirations.
                  This collaborative approach allows us to tailor our solutions
                  to their specific requirements and deliver the best possible
                  outcomes.
                </p>

                <p className="mb-4">
                  But our dedication doesn't stop at project completion. We
                  offer ongoing maintenance and support to ensure that our
                  clients' software solutions remain robust, secure, and
                  up-to-date. Our team of dedicated professionals is always
                  ready to provide prompt assistance and address any issues that
                  may arise.
                </p>

                <p className="mb-4">
                  Choose OZKode as your trusted partner for all your software
                  development and digital transformation needs. Experience the
                  power of tailored technology solutions, seamless
                  collaboration, and unwavering support as we help you navigate
                  the digital landscape and achieve your business objectives.
                  Together, we can turn your vision into reality and drive your
                  business towards a future of limitless possibilities.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Features;
