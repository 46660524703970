import React, { FC, useEffect, useRef } from 'react';
import Slider, { Settings } from 'react-slick';
import { HomeCardItem } from '@components/homes';
import {
  FaAngleRight,
  FaAngleLeft,
  FaArrowRight,
  FaLock,
  FaCreativeCommonsSamplingPlus,
  FaCode,
  FaMobileAlt,
  FaRegCommentAlt,
  FaMoneyBillAlt,
  FaMoneyBillWaveAlt,
  FaMoneyCheckAlt,
  FaCoins,
  FaHome,
  FaPage4,
  FaPeopleArrows,
  FaUserFriends,
  FaArtstation,
  FaDigitalTachograph,
  FaClipboard,
  FaFileContract,
  FaMicroblog,
  FaAlipay,
  FaCodeBranch,
  FaCommentMedical,
  FaPeriscope,
  FaUikit,
  FaIcons
} from 'react-icons/fa';
import Container from '@components/ui/Container';
import ImgHome1 from '../../../assets/images/home-6.png';
import ImgHome2 from '../../../assets/images/home-7.png';
import { Link } from 'react-router-dom';

const SliderArrow = (props) => {
  const { onClick, type, className } = props;
  return (
    <button
      style={{
        right: type === 'prev' ? '60px !important' : '0 !important',
        zIndex: 10
      }}
      onClick={onClick}
      className={
        className +
        ' shadow-md text-lg rounded-full bg-white p-4 mr-2 hover:bg-accent hover:text-accent'
      }>
      {type === 'next' ? <FaAngleRight /> : <FaAngleLeft />}
    </button>
  );
};

export const servs = [
  [
    {
      title: 'Tailored application development',
      description: 'A Party files a complaint',
      icon: <FaArtstation />
    },
    {
      title: 'Seamless enterprise application integration',
      description: 'VCDR Team sends notice to other Party',
      icon: <FaDigitalTachograph />
    },
    {
      title: 'On-premise and cloud-based application development',
      description: 'Parties share/upload the documents',
      icon: <FaClipboard />
    },
    {
      title: 'Mobile application development',
      description:
        'Parties provide clarification as sought by Presiding Officer',
      icon: <FaFileContract />
    },
    {
      title: 'Application migration and modernization',
      description: 'Both Parties appear before Presiding Officer',
      icon: <FaMicroblog />
    },
    {
      title: 'Integration and API development',
      description: 'Presiding Officer passes the Award/Order',
      icon: <FaAlipay />
    },
    {
      title:
        'Application maintenance, bug fixing and post development service ',
      description: 'Presiding Officer passes the Award/Order',
      icon: <FaAlipay />
    },
    {
      title: 'Robust client-server application development',
      description: 'Presiding Officer passes the Award/Order',
      icon: <FaAlipay />
    }
  ],
  [
    { title: 'Custom mobile app development', icon: <FaCodeBranch /> },
    { title: 'iOS app development', icon: <FaCodeBranch /> },
    { title: 'Android app development', icon: <FaCodeBranch /> },
    {
      title: 'Cross-platform (Flatter) app development',
      icon: <FaCodeBranch />
    },
    { title: 'UI/UX design', icon: <FaCodeBranch /> },
    { title: 'App testing and quality assurance', icon: <FaCodeBranch /> },
    { title: 'App maintenance and support', icon: <FaCodeBranch /> },
    {
      title: 'App integration and backend development',
      icon: <FaCodeBranch />
    },
    { title: 'App store deployment', icon: <FaCodeBranch /> },
    {
      title:
        'Optimise apps for different devices including mobile, tab and desktop',
      icon: <FaCodeBranch />
    }
  ],
  [
    { title: 'E-commerce website development ', icon: <FaCommentMedical /> },
    {
      title: 'Shopping cart and payment gateway integration',
      icon: <FaCommentMedical />
    },
    { title: 'Product catalog management', icon: <FaCommentMedical /> },
    { title: 'Order management and fulfillment', icon: <FaCommentMedical /> },
    { title: 'Mobile commerce solutions', icon: <FaCommentMedical /> },
    { title: 'Multichannel selling ', icon: <FaCommentMedical /> },
    { title: 'User experience optimization', icon: <FaCommentMedical /> },
    { title: 'Performance optimization', icon: <FaCommentMedical /> },
    { title: 'Security and compliance', icon: <FaCommentMedical /> },
    { title: 'Analytics and reporting', icon: <FaCommentMedical /> }
  ],
  [
    { title: 'Mobile Banking Solutions', icon: <FaMobileAlt /> },
    { title: 'POS Terminal Integration', icon: <FaMobileAlt /> },
    { title: 'Payment-on-the-Go Solutions', icon: <FaMobileAlt /> },
    { title: 'E-Wallet Development', icon: <FaMobileAlt /> },
    { title: 'Payment Gateway Integration', icon: <FaMobileAlt /> },
    { title: 'Blockchain Solutions', icon: <FaMobileAlt /> },
    { title: 'Financial Analytics Tools', icon: <FaMobileAlt /> },
    { title: 'Regulatory Compliance Solutions', icon: <FaMobileAlt /> },
    { title: 'Robo-Advisory Solutions', icon: <FaMobileAlt /> },
    { title: 'API Integration', icon: <FaMobileAlt /> }
  ],
  [
    { title: 'Property management software', icon: <FaPeriscope /> },
    { title: 'Tenant experience solutions', icon: <FaPeriscope /> },
    { title: 'Data analytics and insights', icon: <FaPeriscope /> },
    { title: 'Property listing and search platforms', icon: <FaPeriscope /> },
    {
      title: 'Property finance and mortgage technology',
      icon: <FaPeriscope />
    },
    { title: 'Conveyancing software', icon: <FaPeriscope /> },
    { title: 'Property data integration', icon: <FaPeriscope /> },
    { title: 'Custom property tech solutions', icon: <FaPeriscope /> }
  ],
  [
    { title: 'User research', icon: <FaUikit /> },
    { title: 'Wireframing and prototyping', icon: <FaUikit /> },
    { title: 'Graphics and visual design', icon: <FaUikit /> },
    { title: 'Information architecture', icon: <FaUikit /> },
    { title: 'Usability testing', icon: <FaUikit /> },
    { title: 'Responsive design', icon: <FaUikit /> },
    { title: 'Accessibility', icon: <FaUikit /> },
    { title: 'UI style guide', icon: <FaUikit /> },
    { title: 'Collaboration with development', icon: <FaUikit /> },
    { title: 'Frontend development', icon: <FaUikit /> }
  ],
  [
    { title: 'Full-time dedicated developers ', icon: <FaIcons /> },
    { title: 'Agile scaling for rapid growth', icon: <FaIcons /> },
    { title: 'Agile development methodology', icon: <FaIcons /> },
    {
      title: 'Flexible working hours to align with your time zone',
      icon: <FaIcons />
    },
    { title: 'Seamless collaboration', icon: <FaIcons /> },
    { title: 'Technical expertise', icon: <FaIcons /> },
    { title: 'Project management', icon: <FaIcons /> },
    { title: 'Regular reporting and updates', icon: <FaIcons /> },
    { title: 'Intellectual property protection', icon: <FaIcons /> }
  ]
];

export const data = [
  {
    id: 1,
    cover: ImgHome1,
    title: 'Custom software development',
    description:
      "Unleash your business's potential with our custom software development. Tailored solutions, expertly crafted, to drive innovation and efficiency, giving you a competitive edge.",
    rating: 5,
    ratingCount: 8,
    price: 25000000,
    category: 'Beginner',
    icon: <FaCode />,
    list: servs[0],
    longDesc:
      "<p class='mb-4'>Unleash your business's potential with our custom software development. Tailored solutions, expertly crafted, to drive innovation and efficiency, giving you a competitive edge.</p> <p class='mb-4'>From in-depth understanding of your goals to precise software development, we deliver robust designs, dynamic web applications, native or cross-platform mobile apps, and scalable cloud solutions.</p> Our rigorous testing ensures reliability, security, and performance, while seamless integration, API development, and ongoing support guarantee a successful software deployment."
  },
  {
    id: 2,
    cover: ImgHome2,
    title: 'Mobile application development',
    description:
      'Transform your ideas into captivating mobile experiences with our comprehensive mobile app development services. From concept to launch, we deliver customized solutions that engage users and drive business growth.    ',
    rating: 5,
    ratingCount: 15,
    price: 20000000,
    category: 'Intermediate',
    icon: <FaMobileAlt />,
    list: servs[1],
    longDesc:
      "<p class='mb-4'>Our Mobile App Development Services offer tailored solutions to meet your unique business requirements. With expertise in iOS and Android platforms, we deliver engaging and user-friendly applications. Our cross-platform development ensures versatility, while intuitive UI/UX design enhances the app experience. Rigorous testing and ongoing maintenance ensure high-performance, bug-free apps. We also offer seamless integration with backend systems and provide support throughout the app deployment process.</p>"
  },
  {
    id: 3,
    cover: ImgHome1,
    title: 'E–commerce ',
    description:
      'Unleash your online business potential with our expertly crafted E-commerce solutions, tailored to drive sales and provide exceptional shopping experiences for your customers.    ',
    rating: 4,
    ratingCount: 7,
    price: 30000000,
    category: 'Beginner',
    icon: <FaRegCommentAlt />,
    list: servs[2],
    longDesc:
      "<p class='mb-4'>Empower your online business with our comprehensive E-commerce solutions. From building custom websites to integrating secure payment gateways, optimizing user experiences, and providing analytics insights, we offer a range of services to drive sales, enhance customer satisfaction, and maximize your success in the digital marketplace.</p"
  },
  {
    id: 4,
    cover: ImgHome2,
    title: 'Financial technology – fintech',
    description:
      'Unlock the full potential of Fintech with our innovative and secure solutions, spanning mobile banking, POS terminals, payment on-the-go, e-wallets, and payment gateways.',
    rating: 4,
    ratingCount: 12,
    price: 30000000,
    category: 'Intermediate',
    icon: <FaCoins />,
    list: servs[3],
    longDesc:
      "<p class='mb-4'>Revolutionize the financial landscape with our comprehensive Fintech solutions. From mobile banking and secure payment gateways to blockchain applications and advanced financial analytics, we offer a range of innovative services to transform processes, enhance user experiences, and drive business growth in the digital finance era.</p>"
  },

  {
    id: 5,
    cover: ImgHome2,
    title: 'Property technology – Proptech',
    description:
      'Leverage cutting-edge technology solutions to streamline property management processes, enhance operational efficiency, and provide innovative solutions for the real estate industry.    ',
    rating: 4,
    ratingCount: 12,
    price: 30000000,
    category: 'Intermediate',
    icon: <FaHome />,
    list: servs[4],
    longDesc:
      "<p class='mb-4'>Our Property Technology services encompass property management software, tenant experience solutions, data analytics, property listing platforms, property finance technology, property data integration, and custom property tech solutions. With a deep understanding of the Australian property business, we leverage cutting-edge technologies to streamline operations, enhance tenant experiences, and drive growth and innovation in the real estate industry.</p>"
  },

  {
    id: 6,
    cover: ImgHome2,
    title: 'UI/UX design    ',
    description:
      'Create visually appealing and intuitive user interfaces that enhance the overall user experience of your digital products, ensuring optimal engagement and customer satisfaction.    ',
    rating: 4,
    ratingCount: 12,
    price: 30000000,
    category: 'Intermediate',
    icon: <FaPage4 />,
    list: servs[5],
    longDesc:
      "<p class='mb-4'>We elevate user experience through thoughtful design. Our UI/UX design services encompass user research, wireframing, visual design, and usability testing to create intuitive, visually captivating interfaces. With a focus on responsiveness, accessibility, and collaboration with development teams, we deliver exceptional user experiences that drive customer satisfaction and success.</p>"
  },

  {
    id: 7,
    cover: ImgHome2,
    title: 'Dedicated Development Team',
    description:
      'Our Dedicated Development Team service provides you with a highly skilled and dedicated team of developers, designers, and engineers who work exclusively on your project.',
    rating: 4,
    ratingCount: 12,
    price: 30000000,
    category: 'Intermediate',
    icon: <FaUserFriends />,
    list: servs[6],
    longDesc:
      "<p class='mb-4'>Our Dedicated Development Team service provides you with a highly skilled and dedicated team of developers, designers, and engineers who work exclusively on your project, ensuring focused attention, seamless collaboration, and efficient delivery of your software development needs.</p> <p class=\"pb-4\">Our Dedicated Development Team service offers expert developers focused on your project's success, with the flexibility to scale the team as needed. We follow Agile principles for efficient development, foster effective communication, and provide access to diverse technical expertise. With dedicated project managers ensuring timely delivery and transparent reporting, we prioritize your project's security and confidentiality.</p>"
  }
];

const PopularHomes = () => {
  const mqlRef = useRef(null);

  useEffect(() => {
    mqlRef.current = window.matchMedia('(max-width: 600px)');
  }, []);

  const sliderConfig = {
    infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: mqlRef.current?.matches ? 1 : 3,
    slidesToScroll: 1,
    prevArrow: <SliderArrow type="prev" />,
    nextArrow: <SliderArrow type="next" />,
    dots: true,
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: () => (
      <div
        style={{
          height: 8,
          width: 30,
          backgroundColor: 'divider',
          display: 'inline-block',
          borderRadius: 4
        }}
      />
    )
  };

  return (
    <div
      id="popular-homes"
      className="slick-courses bg-light py-20 lg:pb-32 lg:py-28">
      <Container>
        <div className="grid grid-cols-12 gap-2 mt-12">
          <div className="col-span-12 lg:col-span-3">
            <div
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'start'
              }}
              className="flex flex-col justify-center">
              <h1 className="font-bold text-black text-4xl lg:text-6xl mb-4">
                Most Popular Services
              </h1>
              <div>
                <p className="text-gray-500 flex items-center carot-shift mr-6">
                  Providing a complete solution for your back office management
                  and online presence
                </p>
              </div>
            </div>
          </div>

          <div className="col-span-12 lg:col-span-9 grid lg:grid-cols-3">
            {data.slice(0, 3).map((item) => (
              <HomeCardItem key={String(item.id)} item={item} />
            ))}
          </div>
          <div className="col-span-12 lg:col-span-12 grid lg:grid-cols-4">
            {data.slice(3, 7).map((item) => (
              <HomeCardItem key={String(item.id)} item={item} />
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default PopularHomes;
