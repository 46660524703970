import React, { FC, useEffect, useRef } from 'react';
import Slider, { Settings } from 'react-slick';
import { HomeCardItem } from '@components/homes';
import { FaAngleRight, FaAngleLeft, FaArrowRight } from 'react-icons/fa';
import Container from '@components/ui/Container';
import ImgHome1 from '../../../assets/images/home-6.png';
import ImgHome2 from '../../../assets/images/home-7.png';
import { Link } from 'react-router-dom';
import Content from './Content';
import img from '@assets/images/girl-book.webp';
import Team from '../Team';

const Result = ({ data }) => {
  const mqlRef = useRef(null);

  useEffect(() => {
    mqlRef.current = window.matchMedia('(max-width: 600px)');
  }, []);

  return (
    <div className="bg-gray-100/50">
      <div className="col-span-12 lg:col-span-9">
        <Content list={data?.list || []} />
        <Team />
      </div>
    </div>
  );
};

export default Result;
