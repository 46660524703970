import { PageBanner } from '@components/common';
import { Contact } from '@components/contact';
import { Banner, Result } from '@components/search';
import { Container } from '@components/ui';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { data as f } from '../data/privacy.jsx';

const PrivacyPage = () => {
  const data = f;

  return (
    <div className="bg-white">
      <PageBanner title="Privacy Policy" dark />
      <Container>
        <div className="py-8">
          {data?.map((item, i) => {
            console.log(data);
            return (
              <div className="mb-6">
                <h4 className="font-bold mb-2">{item.title}</h4>
                {item.para?.map((p) => (
                  <p className="text-secondary text-base mb-4">{p}</p>
                ))}

                <h4 className="mb-2">{item.subtitle}</h4>
                <div className="pl-6 text-secondary">
                  <ul className="list-disc">
                    {item.list?.map((l) => (
                      <li className="mb-2">{l}</li>
                    ))}
                  </ul>
                </div>

                {item.para2?.map((p) => (
                  <p className="text-secondary text-base mb-4">{p}</p>
                ))}

                <h4 className="mb-2">{item.subtitle2}</h4>
                <div className="pl-6 text-secondary">
                  <ul className="list-disc">
                    {item.list2?.map((l) => (
                      <li className="mb-2">{l}</li>
                    ))}
                  </ul>
                </div>
              </div>
            );
          })}

          <p className="py-6 text-gray-400 font-bold">
            This document was last updated on 08 July 2023.{' '}
          </p>
        </div>
      </Container>
    </div>
  );
};

export default PrivacyPage;
