import { Container } from '@components/ui';
import React from 'react';

const PageBanner = ({ title, dark = false }) => {
  return (
    <div>
      <div className={'py-8 pb-0 ' + (dark ? 'bg-light' : 'bg-white')}>
        <Container>
          <div className="flex pb-8 lg:pb-12 justify-between mb-0">
            <div className="border-l-8 border-gray-200">
              <h4 className="text-5xl text-black font-bold pl-4">{title}</h4>
            </div>
            {/* <img src={Img2} alt="" className="h-40" /> */}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default PageBanner;
