import React, { FC, useEffect, useRef } from 'react';
import Slider, { Settings } from 'react-slick';
import { HomeCardItem } from '@components/homes';
import { FaAngleRight, FaAngleLeft, FaArrowRight } from 'react-icons/fa';
import Container from '@components/ui/Container';
import ImgHome1 from '../../../assets/images/home-6.png';
import ImgHome2 from '../../../assets/images/home-7.png';
import { Link } from 'react-router-dom';
import Content from './Content';
import img from '@assets/images/girl-book.webp';

const Result = ({ data }) => {
  const mqlRef = useRef(null);

  useEffect(() => {
    mqlRef.current = window.matchMedia('(max-width: 600px)');
  }, []);

  return (
    <div className="bg-gray-100/50">
      <div className="bg-white">
        <Container>
          <div className="lg:py-8 pb-0">
            <div className="grid grid-cols-12 gap-3 lg:mt-8">
              <div className="col-span-12 lg:col-span-5 flex items-center lg:mt-6">
                <div className="lg:mr-8 p-4 lg:p-0">
                  <img src={img} width={650} height={678} alt="Feature img" />
                </div>
              </div>
              <div className="gridx col-span-12 lg:col-span-7 grid-cols-12 gap-7 flex items-center">
                <div>
                  <h1 className="font-bold text-4xl text-gray-500 mb-6">
                    {data?.title}
                  </h1>
                  <p
                    className="text-gray-500"
                    dangerouslySetInnerHTML={{ __html: data?.longDesc }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className="col-span-12 lg:col-span-9">
        <Content list={data?.list || []} />
      </div>
    </div>
  );
};

export default Result;
