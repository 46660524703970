import { data } from '@components/landing/PopularHome';
import { Banner, Result } from '@components/about';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

const AboutPage = () => {
  const [item, setItem] = useState(null);
  const params = useParams();
  const list = data;
  useEffect(() => {
    setItem(
      list.find((s) => {
        return s.id + '' == params?.id;
      })
    );
  }, [params?.id]);
  return (
    <div>
      <Banner title="About Us" />
      <Result data={item} />
    </div>
  );
};

export default AboutPage;
