import ScrollToTop from '@components/common/ScrollToTop';
import routes from './routes';

function App() {
  return (
    <>
      <ScrollToTop />
      {routes}
    </>
  );
}

export default App;
