import { Button, PageBanner } from '@components/common';
import { data } from '@components/landing/PopularHome';
import { Result } from '@components/service';
import Content from '@components/service/Result/Content';
import { Container } from '@components/ui';
import { models } from '@data/dynamic';
import React, { useEffect, useState } from 'react';
import { FaServicestack } from 'react-icons/fa';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

const Service = () => {
  const list = [
    'Web application development',
    'Mobile application development',
    'Business website development',
    'Machine learning',
    'UX and UI designing',
    'iOS App Development for iOS, iPhone, iPad and Apple Watch.',
    'Android App Development for Phones, Tablets and Android Wear.',
    'Backend data management systems.',
    'Custom software development.',
    'eCommerce, payment systems and shopping carts.',
    'Digital marketing and social media marketing packages',
    'POS',
    'Payment gateway, payment system, fintech;'
  ];

  return (
    <div>
      <PageBanner title="Services We Offer" />
      <div className="lg:pt-6 pb-20 bg-light">
        <Container>
          <div className="grid grid-cols-12 gap-3 mb-12">
            <div className="gridx col-span-12 grid-cols-12 gap-7">
              <div className="mb-6">
                <p className="text-gray-400">
                  Submit your request and Get Quotations from Experts
                </p>
              </div>

              <div className="grid gap-4 grid-cols-12">
                {list.map((l, index) => (
                  <div
                    key={String(index)}
                    className="col-span-12 lg:col-span-6 bg-white rounded-md">
                    <div className="px-4 py-5 flex items-center rounded-lg shadow-xl h-full shadow-gray-200/80">
                      <div className="flex p-4 bg-accent text-lg mr-4 text-white items-center justify-center rounded-full">
                        <FaServicestack />
                      </div>
                      <div className="flex flex-col flex-grow">
                        <h4 className="text-base font-semiboldx mb-2">{l}</h4>
                        {/* <h6 className="text-gray-400 text-sm">{description}</h6> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div>
            <h2 className="text-3xl text-black font-bold mb-8">
              We can make like:
            </h2>
            <div className="grid lg:grid-cols-3">
              {models?.map((item, i) => {
                return (
                  <div className="!mb-6">
                    <div className="">
                      <div
                        className={'lg:order-0 lg:col-start-0 lg:col-span-2'}>
                        <img
                          className=" object-cover bg-white w-full lg:h-80 !rounded-none border-4 outline-4 !p-6"
                          src={item.banner}
                          alt="logo"
                        />
                      </div>

                      <div
                        className={
                          'testimonial-boxx mb-6 !shadow-none !p-4 !items-start !bg-transparent !text-left order-1 lg:col-span-3 text-right'
                        }>
                        <h2 className={'text-xl font-bold text-gray-600 '}>
                          {item.name}
                        </h2>
                        <p className="text-sm text-secondary mb-4 single-line">
                          {item?.domain}
                        </p>
                        <div className={' w-full '}>
                          <div className="mb-2">
                            <Button size="sm">
                              <Link to={`/model/${item.name}`}>Details</Link>
                            </Button>
                          </div>
                          <div>
                            <div className="text-sm text-gray-400 font-bold mr-2 inline-block">
                              Website
                            </div>
                            <div className="text-sm text-accent inline-block">
                              <a href={item?.link}>@{item.name}</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Service;
