import { Contact } from '@components/contact';
import { Portfolio } from '@components/portfolio';
import { Banner, Result } from '@components/search';
import React from 'react';

const PortfolioPage = () => {
  return (
    <div>
      <Portfolio />
    </div>
  );
};

export default PortfolioPage;
