import { Company } from '@components/company';
import { Contact } from '@components/contact';
import { Model } from '@components/model';
import { Portfolio } from '@components/portfolio';
import { Banner, Result } from '@components/search';
import React from 'react';
import { useParams } from 'react-router';

const ModelPage = () => {
  const his = useParams();
  const { companyName } = his;

  return (
    <div>
      <Model name={companyName} />
    </div>
  );
};

export default ModelPage;
