import { Company } from '@components/company';
import { Contact } from '@components/contact';
import { Portfolio } from '@components/portfolio';
import { Banner, Result } from '@components/search';
import React from 'react';
import { useParams } from 'react-router';

const CompanyPage = () => {
  const his = useParams();
  const { companyName } = his;

  return (
    <div>
      <Company name={companyName} />
    </div>
  );
};

export default CompanyPage;
