import { Container } from '@components/ui';
import React, { FC, useRef } from 'react';
import {
  FaArrowLeft,
  FaArrowRight,
  FaBackward,
  FaForward
} from 'react-icons/fa';
import Slider, { Settings } from 'react-slick';
import { data } from './testimonial.data';
import TestimonialItem from './TestimonialItem';
import Img from '@assets/images/illustrations/miking.jpg';

const SliderArrow = (props) => {
  const { onClick, type, className } = props;
  return (
    <span
      style={{
        backgroundColor: 'background.paper',
        color: 'primary.main',
        bottom: '64px !important',
        left: 'unset !important',
        right: type === 'prev' ? '90px !important' : '30px !important',
        zIndex: 10
      }}
      color="inherit"
      onClick={onClick}
      className={
        className +
        ' bg-white shadow-lg cursor-pointer hover:text-accent p-4 rounded-full'
      }>
      {type === 'next' ? <FaArrowRight /> : <FaArrowLeft />}
    </span>
  );
};

const StyledSlickContainer = ({ children }) => (
  <div className="slick-con">{children}</div>
);

const Testimonial = () => {
  const sliderRef = useRef(null);

  const sliderConfig = {
    infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SliderArrow type="prev" />,
    nextArrow: <SliderArrow type="next" />
  };

  return (
    <div id="testimonial" className="py-6 lg:pt-8 slick-testimonials">
      <Container>
        <div className="grid grid-cols-12 gap-5">
          <div className="col-span-12 order-2 lg:order-1 lg:col-span-6 lg:pt-12">
            <h2
              className="mb-2 text-2xl lg:text-5xl font-bold"
              style={{
                position: 'relative',
                lineHeight: 1,
                fontWeight: 'bold'
              }}>
              Our client's{' '}
              <span className="text-5xl lg:text-7xl block">Feedback</span>
            </h2>

            <StyledSlickContainer>
              <Slider ref={sliderRef} {...sliderConfig}>
                {data.map((item, index) => (
                  <TestimonialItem key={String(index)} item={item} />
                ))}
              </Slider>
            </StyledSlickContainer>
          </div>
          <div className="order-1 lg:order-2 col-span-12 lg:col-span-6">
            <div className="pb-6">
              <img
                src={Img}
                width={520}
                height={540}
                style={{ filter: 'hue-rotate(330deg)' }}
                alt="Testimonial img"
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Testimonial;
