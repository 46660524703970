import React, { FC, useEffect, useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import { HomeCardItem } from '@components/homes';
import { FaAngleRight, FaAngleLeft, FaArrowRight } from 'react-icons/fa';
import Container from '@components/ui/Container';
import ImgHome1 from '../../../assets/images/home-6.png';
import ImgHome2 from '../../../assets/images/home-7.png';
import { Link } from 'react-router-dom';
import img from '@assets/images/illustrations/sitting-pc-crop.jpg';
import { Button } from '@components/common';
import axios from 'axios';

const Content = () => {
  const [message, setMessage] = useState({
    type: '',
    content: ''
  });
  const mqlRef = useRef(null);

  useEffect(() => {
    mqlRef.current = window.matchMedia('(max-width: 600px)');
  }, []);

  const sendMessage = (e) => {
    e.preventDefault();
    const api = 'https://api.wurley.com.au/api/ozcode/v1';
    axios
      .post(
        api,
        {
          name: e.target.name.value,
          email: e.target.email.value,
          mobile: e.target.mobile.value,
          content: e.target.content.value
        },
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      .then((res) => {
        setMessage({
          type: 'success',
          content: 'Message sent successfully'
        });
      })
      .catch((err) => {
        console.log(err);
        setMessage({
          type: 'error',
          content: 'Message sending failed. Try again'
        });
      });
  };

  return (
    <div className="contact">
      <div className="lg:my-12">
        <Container>
          <div className="py-8">
            <div className="grid grid-cols-12 gap-3 my-4">
              <div className="col-span-12 lg:col-span-6 flex items-center mt-6">
                <div className="mr-6 pr-6">
                  <img src={img} width={650} height={678} alt="Feature img" />
                </div>
              </div>
              <div className="gridx col-span-12 lg:col-span-6 grid-cols-12 gap-7">
                <div className="lg:ml-6 my-8 lg:my-20">
                  <h1 className="font-bold uppercase text-4xl text-gray-500 mb-6">
                    Contact
                  </h1>
                  <div>
                    <form onSubmit={sendMessage}>
                      <input
                        placeholder="Name"
                        type="text"
                        name="name"
                        required
                        className="px-4 py-2 border-2 outline-black mb-4 border-gray-200 rounded w-full"
                      />
                      <div className="flex flex-wrap lg:flex-nowrap">
                        <input
                          placeholder="Email"
                          type="text"
                          name="email"
                          required
                          className="px-4 py-2 lg:mr-4 border-2 outline-black mb-4 border-gray-200 rounded w-full"
                        />
                        <input
                          placeholder="Mobile Number"
                          type="text"
                          name="mobile"
                          required
                          className="px-4 py-2 border-2 outline-black mb-4 border-gray-200 rounded w-full"
                        />
                      </div>
                      <textarea
                        placeholder="Type anything..."
                        className="px-4 py-2 border-2 outline-black mb-4 border-gray-200 rounded w-full"
                        name="content"
                        required
                        rows={5}></textarea>
                      <div className="flex justify-center">
                        <Button className="btn btn-primary w-full">
                          Submit
                        </Button>
                      </div>
                      {message.type == 'success' ? (
                        <p className="text-green-500 my-2 text-sm">
                          {message.content}
                        </p>
                      ) : (
                        <p className="text-red-500 my-2 text-sm">
                          {message.content}
                        </p>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-6 lg:w-1/2 text-gray-400 text-sm">
            <div className="mb-4">
              <label className="font-semibold mr-2">We would love to hear from you!</label>
              <span>
                 Whether you have a question, need more information about our services, or want to discuss a potential project, our team is here to assist you. Feel free to reach out to us through the contact form, and we will get back to you promptly. We look forward to connecting with you and exploring how we can help your business thrive in the digital landscape.
              </span>
            </div>
            <div>
              <label className="font-semibold mr-2">We are open</label>
              <span>24/7 (Everyday)</span>
            </div>
          </div>
        </Container>
      </div>{' '}
    </div>
  );
};

export default Content;
