import {
  Banner,
  Info,
  PopularHome,
  Portfolio,
  Service,
  Map
} from '@components/landing';
import Testimonial from '@components/landing/Testimonial';

const Landing = () => {
  return (
    <div>
      <Banner />
      <PopularHome />
      <Service />
      <Portfolio />
      <Testimonial />
      <Map />
      <Info />
    </div>
  );
};

export default Landing;
