import { Container } from '@components/ui';
import React, { FC, useRef } from 'react';
import {
  FaArrowLeft,
  FaArrowRight,
  FaBackward,
  FaForward
} from 'react-icons/fa';
import Slider, { Settings } from 'react-slick';
import { data } from './testimonial.data';
import TestimonialItem from './TestimonialItem';
import Img from '@assets/images/home-5.jpg';
import { portfolios } from '@data/dynamic';
import { Link } from 'react-router-dom';

const SliderArrow = (props) => {
  const { onClick, type, className } = props;
  return (
    <span
      style={{
        backgroundColor: 'background.paper',
        color: 'primary.main',
        bottom: '64px !important',
        left: 'unset !important',
        right: type === 'prev' ? '90px !important' : '30px !important',
        zIndex: 10
      }}
      color="inherit"
      onClick={onClick}
      className={
        className +
        ' bg-white shadow-lg cursor-pointer hover:text-accent p-4 rounded-full'
      }>
      {type === 'next' ? <FaArrowRight /> : <FaArrowLeft />}
    </span>
  );
};

const StyledSlickContainer = ({ children }) => (
  <div className="slick-con">{children}</div>
);

const Portfolio = () => {
  const sliderRef = useRef(null);
  const list = portfolios?.slice(0, 3);
  const sliderConfig = {
    // infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SliderArrow type="prev" />,
    nextArrow: <SliderArrow type="next" />
  };

  return (
    <div id="testimonial" className="py-6 bg-black lg:py-8 portfolio-section">
      <Container>
        <StyledSlickContainer>
          {/* <Slider ref={sliderRef} {...sliderConfig}> */}
          <div className="section-wrap lg:grid-cols-3">
            {list?.map((item, index) => (
              <div
                className={
                  'testimonial-box mb-6 lg:!mt-20 ' +
                  (index == 1 ? 'lg:!mb-16 topper' : '')
                }>
                <img
                  className="object-cover lazyloaded"
                  src={
                    'https://img.freepik.com/free-vector/bird-colorful-logo-gradient-vector_343694-1365.jpg'
                  }
                  alt={item?.name}
                  style={{
                    filter:
                      'hue-rotate(380deg) sepia(0.2) brightness(1.2) contrast(.95)'
                  }}
                />
                <div className="back">
                  <h4 className="font-bold text-lg mb-2">{item?.name}</h4>
                </div>
                <div className="div-quote">
                  <p className="text-sm text-gray-500 mb-4">{item?.type}</p>
                </div>
                <p className="text-sm text-gray-400 font-bold">Website</p>
                <p className="text-sm text-black">
                  <a href={item?.link} target="_blank" rel="noreferer noopener">
                    @{item?.name}
                  </a>
                </p>
              </div>
            ))}
          </div>
          {/* </Slider> */}
          <Link
            className="text-accent flex items-center carot-shift text-lg text-right font-bold absolute right-0"
            to="/portfolio">
            See All <FaArrowRight className="ml-2 text-gray-400 transition duration-300" />
          </Link>
        </StyledSlickContainer>
      </Container>
    </div>
  );
};

export default Portfolio;
