import React, { FC } from 'react';

const TestimonialItem = ({ item }) => {
  return (
    <div style={{ padding: '30px' }}>
      <div className="mb-6">
        <h2 className="mb-2 text-lg">{item.title}</h2>
        <h4 className="mb-2 text-sm text-secondary">{item.content}</h4>
      </div>
      <div
        className="shadow-lg bg-white rounded-md p-2 shadow-gray-200"
        style={{
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          width: 320,
          backgroundColor: 'background.paper'
        }}>
        <div
          className="mb-2 mr-4"
          style={{
            borderRadius: '50%',
            height: 52,
            width: 52,
            overflow: 'hidden'
          }}>
          <img
            src={`${item.user.photo}`}
            width={100}
            height={100}
            alt={item.user.name}
          />
        </div>
        <div>
          <h6>{item.user.name}</h6>
          <h4 className="text-secondary text-sm">{item.user.professional}</h4>
        </div>
      </div>
    </div>
  );
};
export default TestimonialItem;
