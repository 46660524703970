import { Container } from '@components/ui';
import React, { FC, useRef } from 'react';
import {
  FaArrowLeft,
  FaArrowRight,
  FaBackward,
  FaForward
} from 'react-icons/fa';
import Slider, { Settings } from 'react-slick';
import { Button, ScrollAnimationWrapper } from '@components/common';
import { motion } from 'framer-motion';
import { useMemo } from 'react';
import getScrollAnimation from '@utils/getScrollAnimation';
import Img2 from '@assets/images/illustrations/time-progress-crop.jpg';
import { portfolios } from '@data/dynamic';
import Banner from '@components/common/PageBanner';
import { Link } from 'react-router-dom';

const SliderArrow = (props) => {
  const { onClick, type, className } = props;

  return (
    <span
      style={{
        backgroundColor: 'background.paper',
        color: 'primary.main',
        bottom: '64px !important',
        left: 'unset !important',
        right: type === 'prev' ? '90px !important' : '30px !important',
        zIndex: 10
      }}
      color="inherit"
      onClick={onClick}
      className={
        className +
        ' bg-white shadow-lg cursor-pointer hover:text-accent p-4 rounded-full'
      }>
      {type === 'next' ? <FaArrowRight /> : <FaArrowLeft />}
    </span>
  );
};

const StyledSlickContainer = ({ children }) => (
  <div className="slick-con">{children}</div>
);

const Portfolio = () => {
  const sliderRef = useRef(null);
  const scrollAnimation = useMemo(() => getScrollAnimation(), []);
  const list = portfolios;
  const sliderConfig = {
    // infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SliderArrow type="prev" />,
    nextArrow: <SliderArrow type="next" />
  };

  return (
    <div id="testimonial" className="testimonial-page bg-light lg:pt-0 pb-20">
      <Banner title="Portfolio" />
      <Container>
        <ScrollAnimationWrapper className="rounded-lg w-full pt-20">
          {list?.map((item, i) => {
            return (
              <div className="section-wrap lg:!mb-12">
                <div
                  // custom={{ duration: 2 }}
                  // variants={scrollAnimation}
                  className="grid gap-6 lg:grid-cols-5">
                  <div
                    className={
                      'lg:mb-6 ' +
                      (i % 2 == 0
                        ? ' lg:order-0 lg:col-start-0 lg:col-span-2'
                        : ' lg:order-1 lg:col-span-2')
                    }>
                    <img
                      className=" object-cover bg-white w-full lg:h-80 !rounded-none border-4 outline-4 !p-6"
                      src={item.banner}
                      alt="logo"
                    />
                  </div>

                  <div
                    className={
                      'testimonial-boxx py-6 mb-6 !shadow-none !p-4 !items-start !bg-transparent !text-left ' +
                      (i % 2 == 0
                        ? '  order-1 lg:col-span-3 text-right'
                        : ' !items-end order-0 lg:col-span-3')
                    }>
                    <h2
                      className={
                        'text-xl mb-4 font-bold text-gray-600 ' +
                        (i % 2 == 0 ? '' : ' !text-right')
                      }>
                      {item.name}
                    </h2>
                    <p
                      className={
                        'text-base text-gray-400 mb-4 ' +
                        (i % 2 == 0 ? '' : ' !text-right')
                      }
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                    <div
                      className={
                        ' w-full ' + (i % 2 == 0 ? '' : ' !text-right')
                      }>
                      <div className="mb-2">
                        <Button size="sm">
                          <Link to={`/portfolio/${item.name}`}>Details</Link>
                        </Button>
                      </div>
                      <div>
                        <div className="text-sm text-gray-400 font-bold mr-2 inline-block">
                          Website
                        </div>
                        <div className="text-sm text-accent inline-block">
                          <a href={item?.link}>@{item.name}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </ScrollAnimationWrapper>
      </Container>
    </div>
  );
};

export default Portfolio;
